
import { defineComponent, onMounted } from "vue";
import MixedWidget2 from "@/components/1crm/dashboard/sekolah/tes.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "dashboard",
  components: {
    MixedWidget2,
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Client");
    });
  },
});
